* {
    position: relative;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-image: url('../Image/1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
}

.ImageGrid {
    margin-top: -8px;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 3fr));
    width: 100%;
    height: 100%;
}

.ImageGridHomePage {
    display: grid;
    top: 15px;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 3fr));
    width: 100%;
}

.OriImage {
    display: block;
    width: 550px;
    height: 550px;
    border: 2px solid rgba(0, 0, 0, 0.4);
    z-index: 2;
    position: fixed;
    left: 28%;
}

.IamgeContainer {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    display: block;
    cursor: pointer;
}

.IamgeContainer img {
    width: 100%;
}

.IamgeContainer:hover {
    box-shadow: 0 0 25px 20px rgba(0, 0, 0, 0.4);
}

.IamgeContainer h5 {
    width: 100%;
    color: white;
    position: absolute;
    display: none;
    transition: ease-in-out 0.8s;
    font-weight: 700;
}

.StyleImage,
.StyleImage1,
.StyleImage2 {
    width: 250px;
    height: 250px;
}

.IamgeContainer:hover h5 {
    display: block;
    bottom: -10px;

}

.StyleImage:hover {
    animation: shake 2s;
    animation-iteration-count: infinite;
}

.StyleImage1:hover {
    animation: shake1 10s;
    animation-iteration-count: infinite;
}

.StyleImage2:hover {
    animation: shake2 10s;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes shake1 {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-50deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(50deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(50deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-50deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-50deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(50deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes shake2 {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(30deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(-30deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(30deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-30deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-30deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(30deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.showMore {
    position: relative;
    left: 180%;
    bottom: 20px;
    /* opacity: 0.7; */
    text-decoration: none;
    cursor: pointer;
    display: block;
    background-color: white;
    color: black;
    font-size: 25px;
    font-weight: 900;
    /* padding: 10px 5px; */
    border-radius: 10px;
    border: none;
}

.shoeMore1 {
    position: relative;
    left: 180%;
    bottom: 20px;
    /* opacity: 0.7; */
    text-decoration: none;
    cursor: pointer;
    display: block;
    background-color: white;
    color: black;
    font-size: 25px;
    font-weight: 900;
    /* padding: 10px 5px; */
    border-radius: 10px;
    border: none;
}

.showMore:hover {
    color: white;
    background-color: black;
}

.BackTop {
    position: fixed;
    width: 55px;
    height: 55px;
    background-color: white;
    color: black;
    border-radius: 5px;
    font-size: 20px;
    right: 11%;
    bottom: 10px;
    text-align: center;
    align-items: center;
    padding: 5px 0;
}

.BackTop::before {
    content: '▲';
    display: block;
    text-align: center;
    color: red;
    line-height: 42px;
}

.BackTop:hover:before {
    content: "Top";
    color: black;
    font-weight: 800;
}

.deleteImage {
    position: absolute;
    right: 10%;
    font-size: 30px;
    z-index: 3;
    cursor: pointer;
    display: none;
}

.searchInput {
    border-radius: 15px;
    left: 2rem;
    border: 2px solid rgba(0, 0, 0, 0.8);
}

::placeholder {
    color: red;
    font-size: 20px;
    font-weight: 700;
}